.publications {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-color: #E1FBFF;
  justify-content: center;
  text-align: center;
  padding-top: 48px;

  .paper-type {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    height: 30px;
    line-height: 30px;
    color: #2CB5C8;
    text-align: left;
    margin-top: 10px; }

  .papers {
    width: 100%;
    gap: 20px;
    max-width: 1140px;
    margin: auto;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    p {
      text-align: justify;
      font-size: 16px;
      font-family: 'Quicksand';
      line-height: 20px;
      font-weight: normal; } } }

@media (max-width: 1199.98px) {
  .publications {
    padding: 32px 20px 64px; } }


@media (max-width: 767px) {
  .publications {
    padding-top: 24px;
    padding-bottom: 48px;

    .papers {
      p {
        font-size: 14px;
        line-height: 20px;
        text-align: justify; } } } }
