.acknowledge {
    background-position: bottom left;
    background-repeat: no-repeat;
    // background-color: #E1FBFF
    padding: 48px 0 64px;

    // .logos
    //     display: flex
    //     flex-wrap: wrap
    //     gap: 20px
    //     max-width: 1140px
    //     margin: 0 auto
    //     .logo
    //         width: 267px
    //         height: 150px

    .logos1 {
        .logo-line1 {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            max-width: max-content;
            margin: 0 auto;
            .logo1 {
                width: 267px;
                height: 150px; } } }
    .content {
        width: 100%;
        p {
            margin-bottom: 24px;
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            text-align: justify;
            color: #666666; } }
    .ack-content {
        width: 100%;
        gap: 20px;
        max-width: 1140px;
        margin: auto;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        p {
            text-align: justify;
            font-size: 16px;
            font-family: 'Quicksand';
            line-height: 22px;
            font-weight: normal; } }
    img {
        width: 100%;
        height: 364px; } }

@media (max-width: 1199.98px) {
    .acknowledge {
        padding: 32px 20px 64px;
        .logos {
            .logo {
                width: calc(25% - 15px);
                height: auto; } } } }

@media (max-width: 767px) {
    .acknowledge {
        padding-top: 24px;
        padding-bottom: 48px;
        .logos {
            .logo {
                width: calc(50% - 10px);
                height: 88px; } }
        .ack-content {
            p {
                font-size: 14px;
                line-height: 20px;
                text-align: justify; } } } }
