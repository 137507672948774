.objectives {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-color: #E1FBFF;
  justify-content: center;
  text-align: center;
  padding-top: 48px;

  .objective_title {
    font-family: 'Quicksand';
    font-style: normal;
    font-size: 20px;
    line-height: 25px;
    align-items: center;
    color: #2CB5C8; }

  .objective_overview {
    display: flex;
    justify-content: center;
    margin: 40px 80px;
    max-width: 1140px;
    margin: 40px auto;

    .objective_big_column {
      width: calc((100%-40px)/3);
      display: flex;
      flex-direction: column;
      align-items: center;
      .overview_title {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 64px;
        line-height: 22px; }
      .overview_image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 246px;
        height: 227px;
        // padding: 20px
        margin-top: 20px; }
      .overview_list_items {
        margin-top: 32px;
        padding: 15px;
        height: 120px;
        width: 100%;
        display: flex;
        justify-content: center;

        .bold_word {
          font-weight: 700;
          font-style: normal; }

        ul {
          list-style-image: url("../../images/body/objective/custom_list_bullet.svg");

          li {
            text-align: left;
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #333333;
            padding-left: 10px; } } } }

    .objective_small_column {
      img {
        margin-top: 178px;
        width: 85px; } } }

  .objective_details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 64px auto;
    background: linear-gradient(101.79deg, #2CB5C8 0%, #63D1DF 100%);
    border-radius: 4px;
    padding: 60px 95px;
    max-width: 1140px;

    ul {
      margin: 0;
      padding: 0;
      list-style: None;
      .custom_bullet {
        display: flex;
        img {
          width: 10px;
          height: 25px;
          margin-right: 13px; } }
      li {
        text-align: left;
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #FFFFFF;
        width: 100%;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0; } } } } }

@media (max-width: 1199.98px) {
  .objectives {
    flex-direction: column;
    padding: 40px 0px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .objective_title {
      width: 90%;
      text-align: center;
      padding: 0 20px; }

    .objective_overview {
      width: 100%;
      margin: 0 0 40px;
      padding: 0 20px;
      .objective_big_column {
        width: 100%;
        .overview_title {
          font-family: 'Quicksand';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #333333; }
        .overview_image {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 164px;
          margin-bottom: 24px;
          img {
            height: 100%;
            width: auto; } }

        .overview_list_items {
          padding: 0;
          margin: 0;
          height: auto;
          ul {
            width: 100%;
            list-style-image: url("../../images/body/objective/custom_list_bullet.svg");
            text-align: justify;
            margin: 0;
            li {
              font-family: 'Quicksand';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #333333;
              padding-left: 10px; } } } }

      .objective_small_column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        margin-bottom: 24px;
        img {
          width: 60px;
          margin-top: 10px; } } }

    .objective_details {
      padding: 20px;
      margin: 0 20px; } } }

@media (max-width: 767px) {
  .objectives {
    padding-top: 0;
    .objective_overview {
      flex-direction: column;
      .objective_big_column {
        .overview_image {
          width: 246px;
          height: auto;
          margin: 0 0 24px;
          img {
            width: 100%;
            height: auto; } }
        .overview_title {
          margin-bottom: 24px; } }
      .objective_small_column {
        margin: 24px auto;
        img {
          transform: rotate(90deg);
          width: 46px; } } }
    .objective_title {
      padding: 0;
      margin: 24px 0;
      p {
        margin: 0; } } } }
