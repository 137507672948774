.products {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-color: #E1FBFF;
  justify-content: center;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 48px; }

.product_title {
  width: 100%;
  gap: 20px;
  max-width: 1140px;
  margin: auto;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column; }

.product_video {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16/9; }
.product_type {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  height: 30px;
  line-height: 30px;
  color: #2CB5C8;
  text-align: center;
  margin-top: 10px; }
