.resource {
    background-position: bottom left;
    background-repeat: no-repeat;
    padding: 48px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tabGroup {
        display: flex;
        justify-content: space-between;
        width: 600px; }

    .content {
        max-width: 1140px;
        margin: 0 auto;

        .textGroup {
            p {
                margin-top: 64px;
                font-family: 'Quicksand';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                text-align: justify;
                color: #666666;
                flex: none;
                order: 0;
                align-self: stretch;
                flex-grow: 0;
                margin-bottom: 0;

                span {
                    color: #2CB5C8; } } }

        .colGroup {
            margin-top: 40px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 24px;

            .resourceCol {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                .colTitle {
                    font-family: 'Quicksand';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 25px;
                    text-align: center;
                    color: #2CB5C8; }

                .colImage {
                    margin-top: 20px; }

                .colDesc {
                    margin-top: 20px;
                    font-family: 'Quicksand';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: justify;
                    color: #333333; }

                .colDescTool {
                    margin-top: 20px;
                    font-family: 'Quicksand';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: left;
                    color: #333333; }

                .tryBtn {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 13px 16px;
                    gap: 10px;
                    margin-top: 24px;

                    width: 170px;
                    height: 46px;

                    border: 1px solid #2CB5C8;
                    border-radius: 1000px;
                    cursor: pointer;
                    text-decoration: none;

                    .tryText {
                        width: 75px;
                        height: 20px;
                        font-family: 'Quicksand';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                        text-align: center;
                        color: #2CB5C8; } }

                .mobileMarket {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 18px;
                    .market {
                        width: 170px;
                        height: 50px; } } } } } }


@media (max-width: 1199.98px) {
    // .block_title
    //  // font-size: 24px
    .resource {
        padding: 32px 20px 64px;

        .tabGroup {
            width: 100%;
            justify-content: center;
            gap: 20px;
            .tabItem {
                font-size: 16px;
                line-height: 24px;
                width: 260px;
                justify-content: center;
                height: 48px;
                align-items: center;
                padding: 0; } }

        .content {
            .textGroup {
                p {
                    margin-top: 32px; } }
            .colGroup {
                margin-top: 20px; } } } }

@media (max-width: 767px) {
    .resource {
        padding-bottom: 48px;
        padding-top: 0;
        .tabGroup {
            margin-top: 24px;
            .tabItem {
                width: calc(50% - 10px);
                font-size: 14px;
                line-height: 22px; } }
        .content {
            .textGroup {
                p {
                    margin-top: 24px; } }
            .colGroup {
                margin-top: 24px;
                flex-direction: column;
                .resourceCol {
                    .mobileMarket {
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        .market {
                            width: 150px;
                            height: 50px; } } } }
            .colImage {
                img {
                    width: 229px; } } } } }

