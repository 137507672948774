#workshop {
    max-width: 1204px;
    margin: 0 auto;
    .subtitle {
        color: #212529;
        font-size: 24px;
        font-weight: 700;
        margin-top: 32px; }
    .sub-content {
        margin: 32px 0; }
    .workshop_member {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 32px;
        .workshop_member_item {
            .workshop_member_item_main {
                position: relative;
                cursor: pointer;
                .workshop_member_avatar {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    border-radius: 8px;
                    min-height: 380px;
                    max-height: 380px; }
                .des_person {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    overflow-y: auto;
                    background: #2CB5C8E5;
                    padding: 18px;
                    color: white;
                    font-size: 13px;
                    display: none;
                    border-radius: 8px;
                    transition: all 1s;
                    opacity: 0; } }
            .workshop_member_item_main:hover .des_person {
                display: block;
                opacity: 0.9;
                transition: all 1s; }
            .person_name {
                margin-top: 16px;
                color: #2CB5C8;
                font-size: 24px;
                font-weight: 700; }
            .university_name {
                color: #212529;
                font-size: 16px;
                font-weight: 600; }
            .email {
                color: #666666;
                font-size: 16px;
                font-weight: 400;
                img {
                    margin-right: 10px;
                    width: 20px; } }
            .slide {
                color: #007AFF;
                font-size: 16px;
                font-weight: 400;
                text-decoration: underline;
                img {
                    width: 20px;
                    margin-right: 10px; } } } } }

@media (max-width: 767px) {
    #workshop {
        max-width: 95%;
        .subtitle {
            font-size: 20px; }
        .sub-content {
            margin: 16px 0; }
        .workshop_member {
            grid-template-columns: repeat(1, minmax(0, 1fr));
            gap: 16px;
            .workshop_member_item {
                .workshop_member_item_main {
                    .workshop_member_avatar {
                        min-height: 200px; }
                    .des_person {
                        padding: 8px;
                        font-size: 12px;
                        opacity: 0.9; } }
                .person_name {
                    font-size: 18px; }
                .university_name {
                    font-size: 14px; }
                .email {
                    font-size: 14px;
                    img {
                        width: 16px; } }
                .slide {
                    font-size: 14px;
                    img {
                        width: 16px; } } } } } }
